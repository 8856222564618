import "./contactus-dialog.scss";
import React from "react"
import ContactUs from "../contactus/contactus.jsx"

class ContactUsDialog extends React.Component{
  constructor(props){
    super(props);
    this.toggleDialog = this.toggleDialog.bind(this);
  }

  toggleDialog(){
    this.props.toggleDialog();
  }

  render (){
    return (
      <article className={this.props.show?'contactus-dialog':'contactus-dialog__hide'}>
        <div className="contactus-dialog__wrap">
          <div className="contactus-dialog__close-wrap">
            <button onClick={this.toggleDialog} className="contactus-dialog__close-btn">X</button>
          </div>
          <ContactUs></ContactUs> 
        </div>
        <div onClick={this.toggleDialog} className="contactus-dialog__background" >
        </div>
      </article>
    )
  }

}

export default ContactUsDialog 

