import "./contactus-btn.scss";
import React from "react"
import icon from "../../images/contactus-btn/email.svg"

import ContactusDialog from "../contactus-dialog/contactus-dialog.jsx"

class ContactUsBtn extends React.Component{
  state = {
    displayDialog: false,
  }

  constructor(props){
    super(props);
    this.toggleDialog = this.toggleDialog.bind(this);
  }

  toggleDialog(){
    this.setState(
      {displayDialog: !this.state.displayDialog},
    );
  }

  render (){
    return (
      <article className="contactus-btn">
        <ContactusDialog toggleDialog={this.toggleDialog} show={this.state.displayDialog}/>
        <button onClick={this.toggleDialog} className="contactus-btn__btn">
          <img className="contactus-btn__icon" src={icon} alt="" />
        </button>
      </article>
    )
  }

}

export default ContactUsBtn
