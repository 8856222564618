import React from "react"
import PropTypes from "prop-types"

import Navigation from "./navigation/navigation.jsx"
import Footer from "../components/footer/footer.jsx"
import "./layout.scss"
import ContactUsBtn from "../components/contactus-btn/contactus-btn.jsx"

const Layout = ({ children }) => {


  return (
    <article className="layout">
      <Navigation/>
      {children}
      <Footer/>
      <ContactUsBtn />
    </article>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
