import React from "react"
import { Link } from "gatsby"
import "./navigation.scss"

import logoWhite from "../../images/navigation/logo-white.png"
import logoTitle from "../../images/navigation/logo-title-white.png"
import iconOpen from "../../images/navigation/menu-white.svg"
import iconClose from "../../images/navigation/close-icon-white.svg"
import { globalHistory } from '@reach/router'

class Navigation extends React.Component{

    state = {
      isMenuOpen: true,
      isClosedByResize: false,
    }

    componentDidMount() {
      window.addEventListener("resize", this.handleResize);
      this.handleResize()
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.handleResize);
    }

    switchMenu(){
      this.setState(
        {
          isMenuOpen: !this.state.isMenuOpen,
          isClosedByResize: false,
        })
    }

    handleResize = () => {
      const isDesktop = window.innerWidth>=768;
      this.setState(
        {
          isMenuOpen: isDesktop,
          isClosedByResize: !isDesktop,
        })
    };

    animateMenu(){
      let menuClass = "navigation__menu"
      console.log(globalHistory.location.pathname);
      if(this.state.isClosedByResize){
        menuClass += " navigation__menu-close-by-resize"
      }
      else{
        if(this.state.isMenuOpen){
          menuClass += " navigation__menu--open";
        }
        else{
          menuClass += " navigation__menu--close";
        }
      }
      return menuClass;
    }

    getNavClass(){
      let classNav="navigation";
      if(globalHistory.location.pathname==='/medisign/'){
        classNav+=" navigation__blue"
      } else{
        classNav+=" navigation__green"
      }
      return classNav;
    }

    render (){
      return (
        <nav className={this.getNavClass()}>
        <div className="navigation__menu-mobile">
          <button className="navigation__menu-btn" 
            onKeyDown={()=>this.switchMenu()} onClick={()=>this.switchMenu()}>
            <img className="navigation__menu-icon" 
                 alt="botón para cerrar y abrir el menú"
                 src={this.state.isMenuOpen?iconClose:iconOpen}/>
          </button>
        </div>
        <div className={this.animateMenu()}>
          <Link className={this.state.isMenuOpen?
            "navigation__logo-wrap navigation__link-opened":
            "navigation__logo-wrap navigation__link-closed"} to="/">
            <img alt="Logo" className="navigation__logo" src={logoWhite}></img>
            <img alt="Medsys" className="navigation__logo-title" 
                 src={logoTitle}/>
          </Link>
          <Link className={this.state.isMenuOpen?
            "navigation__link navigation__link-opened":
            "navigation__link navigation__link-closed"} to="/">
            <span className="navigation__link-bracket">[</span>
            Home
            <span className="navigation__link-bracket">]</span>
          </Link>
          <Link className={this.state.isMenuOpen?
            "navigation__link navigation__link-opened":
            "navigation__link navigation__link-closed"}  to="/medsys/">
          <span className="navigation__link-bracket">[</span>
            Medsys
          <span className="navigation__link-bracket">]</span>
        </Link>
        <Link className={this.state.isMenuOpen?
          "navigation__link navigation__link-opened":
          "navigation__link navigation__link-closed"} to="/medisign/">
          <span className="navigation__link-bracket">[</span>
          Medisign
          <span className="navigation__link-bracket">]</span>
        </Link>
        <div className="navigation__other-links">
          <a className={this.state.isMenuOpen?
            "navigation__link navigation__link-opened":
            "navigation__link navigation__link-closed"}  
            href="https://web.medsys.co:3443/medisign/login?state=0">
              <span className="navigation__link-bracket">[</span>Login to
              Medisign<span className="navigation__link-bracket">]</span> </a>
        </div>
      </div>
      </nav>
    )
  }

}

export default Navigation
