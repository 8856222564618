import "./footer.scss";
import React from "react"

import Phone from "../../images/footer/phone.svg"
import Mail from "../../images/footer/mail.svg"
import Place from "../../images/footer/place.svg"

class Footer extends React.Component{
  render (){
    return (
      <article className="footer">
        <div className="footer__container">
          <div className="footer__item">
            <div className="footer__icon-wrap">
              <img className="footer__icon" src={Phone} alt="" />
            </div>
            <div className="footer__description">
              <p className="footer__info">(+571)7042397</p>
              <p className="footer__info">3152335038</p>
            </div>
          </div>
          <div className="footer__item">
            <div className="footer__icon-wrap">
              <img className="footer__icon" src={Mail} alt="" />
            </div>
            <div className="footer__description">
              <p className="footer__info">comercial@medsys.co</p>
              <p className="footer__info">soporte@medsys.co</p>
            </div>
          </div>
          <div className="footer__item">
            <div className="footer__icon-wrap">
              <img className="footer__icon" src={Place} alt="" />
            </div>
            <div className="footer__description">
              <p className="footer__info">
                Cra 53a No. 104-75
              </p>
            </div>
          </div>
        </div>

      </article>
    )
  }

}

export default Footer
